<template>
  <div class="manage-clients">
    <div class="container fluid">
      <legal-client-table
        :getClientsAPI="clientAPI.getClients"
        detailRouteName="ManageClientsDetail"
      ></legal-client-table>
    </div>
  </div>
</template>

<script>
import LegalClientTable from "@/components/GlobalComponents/Shared/Lawyer/LegalClientTable";
import { client as clientAPI } from "@/api";

export default {
  components: {
    LegalClientTable
  },
  mixins: [],
  props: {},
  data: function () {
    return { clientAPI };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>

